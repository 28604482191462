import { useContext, useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import "./App.scss";
import Intro from "./views/Intro/Intro";
import UserChoices from "./views/UserChoices/UserChoices";
import { isMobile } from "./services";
import Cards from "./views/Cards/Cards";
import Vitrine from "./views/Vitrine/Vitrine";
import Contact from "./views/Contact/Contact";
import { ThemeModeContext } from "./contexts/ThemeModeContext";

function App() {
  const initialThemeMode = useContext(ThemeModeContext);
  const [themeMode, setThemeMode] = useState(initialThemeMode);

  return (
    <ThemeModeContext.Provider value={{ themeMode, setThemeMode }}>
      <ParallaxProvider>
        <div className={`${themeMode}`}>
          {!isMobile && <UserChoices />}
          <Intro />
          <Cards />
          <Vitrine />
          <Contact />
          <div
            style={{
              paddingBottom: "10rem",
            }}
          ></div>
        </div>
      </ParallaxProvider>
    </ThemeModeContext.Provider>
  );
}

export default App;
