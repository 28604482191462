import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import "./Contact.scss";
import { email } from "../../services/Email";
import CustomAlert from "../../components/Alert/Alert";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";

export default function Contact() {
  const { themeMode } = useContext(ThemeModeContext);
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [banner, setBanner] = useState({ bool: false, value: "" });

  const { t } = useTranslation();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMail();
  };

  const sendMail = (e) => {
    var re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(String(form.email).toLowerCase())) {
      setBanner({ bool: true, value: "Wrong email value" });
    } else {
      setBanner({ bool: false, value: "" });
      setIsLoading(true);
      email(form, setBanner);
    }
  };

  useEffect(() => {
    if (banner.bool) {
      setIsLoading(false);
      setTimeout(() => {
        setBanner({ bool: false, value: "" });
      }, 5000);
    }
  }, [banner]);

  return (
    <div id="contact">
      <CustomAlert banner={banner} setBanner={setBanner} />
      <h2 className="title">{t("talk.title")}</h2>
      <div className={`arrow-background-form background-form-${themeMode}`}>
        <div className="form-css">
          <Form
            autoComplete="on"
            className="form-position"
            onSubmit={handleSubmit}
          >
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label style={{ color: "white" }} for="name">
                    {t("form.name")}
                  </Label>
                  <Input
                    type="text"
                    required
                    id="name"
                    name="name"
                    onChange={(e) => handleChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label style={{ color: "white" }} for="email">
                    {t("form.email")}
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label style={{ color: "white" }} for="phone">
                    {t("form.phone")}
                  </Label>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    onChange={(e) => handleChange(e)}
                  />
                </FormGroup>
              </Col>
              <Col sm={{ size: 7, offset: 1 }}>
                <Label style={{ color: "white" }} for="message">
                  {t("form.message")}
                </Label>
                <Input
                  type="textarea"
                  name="text"
                  rows="6"
                  id="message"
                  onChange={(e) => handleChange(e)}
                  required
                  label="message"
                />
              </Col>
              <br />
              <br />
            </Row>
            <div className="center">
              <button className="button" type="submit">
                {!isLoading ? t("form.send") : <Spinner color="info" />}
              </button>
            </div>
            <br />
          </Form>
          <div className={`ligne-form ligne-${themeMode}`}></div>
        </div>
      </div>
    </div>
  );
}
