import { useTranslation } from "react-i18next";
import CardComponent from "../../components/Card/Card";
import "./Cards.scss";

export default function Cards({ theme }) {
  const { t } = useTranslation();

  return (
    <div className="cards" id="experiences">
      <CardComponent
        competences={[
          t("cards.cdiscount.competences.first"),
          t("cards.cdiscount.competences.second"),
          t("cards.cdiscount.competences.third"),
          t("cards.cdiscount.competences.fourth"),
          t("cards.cdiscount.competences.fifth"),
          t("cards.cdiscount.competences.sixth"),
        ]}
        description={t("cards.cdiscount.description")}
        img="./media/cdiscount.png"
        secondTitle={t("cards.cdiscount.secondTitle")}
        subtitle={t("cards.cdiscount.subtitle")}
        technos="React, React-admin, Jest, Azure, Material-ui, Jira, Github"
        title="Cdiscount / Cars"
      />
      <CardComponent
        competences={[
          t("cards.bison.competences.first"),
          t("cards.bison.competences.second"),
          t("cards.bison.competences.third"),
        ]}
        description={t("cards.bison.description")}
        img="./media/bison.png"
        secondTitle={t("cards.bison.secondTitle")}
        subtitle={t("cards.bison.subtitle")}
        technos="Vanilla javascript, Spip CMS, Jenkins, Jira, Github"
        title="Bison Futé"
      />
      <CardComponent
        competences={[
          t("cards.atlas.competences.first"),
          t("cards.atlas.competences.second"),
          t("cards.atlas.competences.third"),
        ]}
        description={t("cards.atlas.description")}
        img="./media/atlas.png"
        secondTitle={t("cards.atlas.secondTitle")}
        subtitle={t("cards.atlas.subtitle")}
        technos="React Native, Firebase, Ccode, App store connect, Google Play Console, Github"
        title="Atlas Sport"
      />
    </div>
  );
}
