import { Alert } from "reactstrap";
import "./Alert.scss";
import { useTranslation } from "react-i18next";

export default function CustomAlert({ banner, setBanner }) {
  const { t } = useTranslation();

  return (
    <Alert
      isOpen={banner.bool}
      toggle={() => setBanner({ bool: false, value: "" })}
      className="alert-fixed"
      color={banner.value === "ok" ? "success" : "danger"}
    >
      {banner.value === "ok" ? (
        <div>{t("Alert.ok")}</div>
      ) : (
        <div>
          {t("Alert.oops")}
          <a
            className="alert-link"
            href="mailto: contact@clembesso.com"
            rel="noreferrer"
          >
            {t("Alert.here")}
          </a>
          .
        </div>
      )}
    </Alert>
  );
}
