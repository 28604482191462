import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-scroll-parallax";

import "./Intro.scss";
import { isMobile } from "../../services/";
import { THEME, ThemeModeContext } from "../../contexts/ThemeModeContext";

export default function Intro() {
  const { themeMode } = useContext(ThemeModeContext);
  const [displayedName, setDisplayedName] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const { t } = useTranslation();
  const name = "Clément Bessonnet";

  useEffect(() => {
    if (currentIndex < name.length && !isMobile) {
      const timeoutId = setTimeout(() => {
        setDisplayedName((prevName) => prevName + name[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
    setDisplayedName(name);
  }, [currentIndex]);

  return (
    <div className="intro">
      <div
        className="form"
        style={{ backgroundColor: THEME[themeMode].backgroundColor }}
      >
        <Parallax translateX={isMobile ? [0, 0] : [170, -170]}>
          <img
            className="profilPic"
            src="./media/clement.png"
            alt="profil-pic"
          />
        </Parallax>
        <Parallax translateX={isMobile ? [0, 0] : [-70, 70]}>
          <div className="name">
            <h1>{displayedName}</h1>
            <h2>{t("intro.dev")}</h2>
          </div>
        </Parallax>
      </div>
      <div className="global-resume">
        <div className="resume">
          <h2>{t("intro.graduated")}</h2>
          <h3>{t("intro.resume")}</h3>
        </div>
        <div className="dev-vector-container">
          <img
            className="dev-vector"
            src="./media/dev-vector.png"
            alt="dev-vector"
          />
        </div>
      </div>
      <div className="list">
        <a href="#experiences">
          <div className="list-cate">
            <img
              className="icon-list"
              src={`./media/freelance-${THEME[themeMode].theme}.png`}
              alt="website-icon"
            />
            <p>{t("intro.experiences")}</p>
          </div>
        </a>
        <a href="#website">
          <div className="list-cate">
            <img
              className="icon-list"
              src={`./media/website-${THEME[themeMode].theme}.png`}
              alt="website-icon"
            />
            <p>{t("intro.vitrine")}</p>
          </div>
        </a>
        <a href="#contact">
          <div className="list-cate">
            <img
              className="icon-list"
              src={`./media/contact-${THEME[themeMode].theme}.png`}
              alt="website-icon"
            />
            <p>{t("intro.contact")}</p>
          </div>
        </a>
      </div>
    </div>
  );
}
