import { useContext, useEffect, useState } from "react";
import "./UserChoices.scss";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import { useTranslation } from "react-i18next";

export default function UserChoices() {
  const { themeMode, setThemeMode } = useContext(ThemeModeContext);
  const [french, setFrench] = useState(true);

  const { i18n } = useTranslation();

  const updateThemeMode = () => {
    setThemeMode(themeMode === "dark" ? "light" : "dark");
  };

  useEffect(() => {
    french ? i18n.changeLanguage("fr-FR") : i18n.changeLanguage("en-US");
  }, [french, i18n, setFrench]);

  const updateLanguage = () => {
    setFrench(!french);
  };

  return (
    <div className="buttons">
      <input
        type="image"
        src="./media/dark-mode.png"
        className="icon"
        onClick={() => updateThemeMode()}
        alt="dark-mode"
      />
      <input
        type="image"
        src="./media/language.png"
        className="icon"
        onClick={() => updateLanguage()}
        alt="language"
      />
    </div>
  );
}
