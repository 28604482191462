import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import "./Card.scss";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";

export default function CardComponent({
  competences,
  description,
  img,
  subtitle,
  technos,
  title,
  secondTitle,
}) {
  const { themeMode } = useContext(ThemeModeContext);
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { t } = useTranslation();

  const toggleModal = () => setIsOpenModal(!isOpenModal);
  return (
    <div className="cardGlobal">
      <Card
        className={`bg-${themeMode} cardCustom`}
        onClick={() =>
          window.innerWidth <= 900
            ? setIsOpenCollapse(!isOpenCollapse)
            : setIsOpenModal(!isOpenModal)
        }
      >
        <img alt={title} src={img} style={{ borderRadius: "1rem 1rem 0 0" }} />
        <CardBody>
          <CardTitle tag="h5">
            {title} - {secondTitle}
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            {subtitle}
          </CardSubtitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            {technos}
          </CardSubtitle>
          <CardText>{t("others.see-more")}</CardText>
        </CardBody>
      </Card>
      <Collapse isOpen={isOpenCollapse}>
        <Card className={`backgroundCard-${themeMode}`}>
          <CardTitle tag="h4" className="collaspeCardTitle">
            {title} - {secondTitle}
          </CardTitle>
          <CardBody className="bodyCard">
            <p>{description}</p>
            {competences.map((competence, id) => (
              <p>&#x2022; {competence}</p>
            ))}
          </CardBody>
        </Card>
      </Collapse>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        className={`backgroundCard-${themeMode}`}
      >
        <ModalHeader toggle={toggleModal} className="titleCard">
          {title} - {secondTitle}
        </ModalHeader>
        <ModalBody className="bodyCard">
          <p>{description}</p>
          <ul>
            {competences.map((competence, id) => (
              <li key={id}>{competence}</li>
            ))}
          </ul>
        </ModalBody>
      </Modal>
    </div>
  );
}
