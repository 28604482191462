import { collection, addDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

import { app } from "./FirebaseConf";

export const email = (form, setBanner) => {
  const db = getFirestore(app);
  addDoc(collection(db, "mail"), {
    to: form.email,
    message: {
      subject: "Nouveau message de clembesso.com",
      text: form.text,
      html: `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml">
            <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            
            <style>
            body{
              background:#31E9D4;
              text-align: center;
              padding: 1%;
            }
            </style>
            
            </head>
            <body style="width:100% !important; -webkit-text-size-adjust:100%; -ms-text-size-adjust:100%; margin:0; padding:0;">
            <p><h3>Bonjour ${form.name}, </h3></p>
            <h4>J'ai bien reçu votre message et reviens vers vous le plus vite possible !<br>
            <br>
            À très bientôt<br>
            Cordialement<br>
            <br>
            <b><i>Clément</i></b><br></h4>
            <br>
            <h5>Votre message :</h5>
            <p>${form.text}</p>
        <h5><i>Vos informations : <br>
            ${form.name}<br>
            ${form.phone}<br>
            ${form.email}
        </i></h5>
              </body>
            </html>
            
        `,
    },
  })
    .then((response) => {
      addDoc(collection(db, "mail"), {
        to: "contact@clembesso.com",
        message: {
          subject: "Nouveau message depuis clembesso.com",
          text: form.text,
          html: `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                <html xmlns="http://www.w3.org/1999/xhtml">
                <head>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                
                <style>
                body{
                  background:#31E9D4;
                  text-align: center;
                  padding: 1%;
                }
                </style>
                
                </head>
                <body style="width:100% !important; -webkit-text-size-adjust:100%; -ms-text-size-adjust:100%; margin:0; padding:0;">
                <p><h3>Bonjour ${form.name}, </h3></p>
                <h4>J'ai bien reçu votre message et reviens vers vous le plus vite possible !<br>
                <br>
                À très bientôt<br>
                Cordialement<br>
                <br>
                <b><i>Clément</i></b><br></h4>
                <br>
                <h5>Votre message :</h5>
                <p>${form.text}</p>
            <h5><i>Vos informations : <br>
                ${form.name}<br>
                ${form.phone}<br>
                ${form.email}
            </i></h5>
                  </body>
                </html>
                
            `,
        },
      });
      setBanner({ bool: true, value: "ok" });
    })
    .catch(() =>
      setBanner({
        bool: true,
        value: "error",
      })
    );
};
