import { createContext } from "react";

export const ThemeModeContext = createContext("dark");

export const THEME = {
  light: {
    backgroundColor: "#20a39e",
    theme: "black",
  },
  dark: {
    backgroundColor: "#052136",
    theme: "white",
  },
};
