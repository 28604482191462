import { useTranslation } from "react-i18next";
import "./Vitrine.scss";
import { useContext } from "react";
import { THEME, ThemeModeContext } from "../../contexts/ThemeModeContext";

export default function Vitrine({ theme }) {
  const { themeMode } = useContext(ThemeModeContext);
  const { t } = useTranslation();

  return (
    <div className="vitrine" id="website">
      <div className="title">
        <h1>{t("vitrine.title")}</h1>
      </div>
      <div className={`background-intro background-form-${themeMode}`}>
        <div className="intro-text">
          <p>
            <img
              src={`./media/monitor-${THEME[themeMode].theme}.png`}
              type="media/png"
              className="img-intro-text"
              alt="monitor"
            />
            <span>{t("vitrine.1")}</span> <br />
            <span>{t("vitrine.2")}</span> <br />
            <span>{t("vitrine.3")}</span>
          </p>
        </div>
        <div className="intro-text-partdeux">
          <p>
            <img
              src={`./media/responsive-${THEME[themeMode].theme}.png`}
              type="media/png"
              className="img-intro-text-mobile"
              alt="responsive design"
            />
            <span>{t("vitrine.4")}</span> <br />
            <span>{t("vitrine.5")}</span>
          </p>
        </div>
        <div className={`ligne ligne-${themeMode}`}></div>
      </div>

      <div>
        <h2 className="title" id="service">
          {t("service.title")}
        </h2>
        <div className={`background-intro-deux background-form-${themeMode}`}>
          <div className="intro-text-deux">
            <div className="img-service-column">
              <img
                src={`./media/leadership-${THEME[themeMode].theme}.png`}
                type="image/png"
                className="img-service"
                alt="leadership"
              />
              <img
                src={`./media/premium-${THEME[themeMode].theme}.png`}
                type="image/png"
                className="img-service"
                alt="premium"
              />
              <img
                src={`./media/cooperation-${THEME[themeMode].theme}.png`}
                type="image/png"
                className="img-service"
                alt="cooperation"
              />
            </div>
            <p>
              <span>{t("service.1")}</span>
              <br />
              <span>{t("service.2")}</span>
              <br />
              <span>{t("service.3")}</span>
              <br />
              <span>{t("service.4")}</span>
              <br />
              <span>{t("service.5")}</span>
              <br />
              <span>{t("service.6")}</span>
              <br />
              <span>{t("service.7")}</span>
              <br />
              <span>{t("service.8")}</span>
            </p>
          </div>
          <div className={`ligne-deux ligne-${themeMode}`}></div>
        </div>
      </div>
    </div>
  );
}
